exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agregar-direccion-js": () => import("./../../../src/pages/agregar-direccion.js" /* webpackChunkName: "component---src-pages-agregar-direccion-js" */),
  "component---src-pages-buscar-js": () => import("./../../../src/pages/buscar.js" /* webpackChunkName: "component---src-pages-buscar-js" */),
  "component---src-pages-carrito-js": () => import("./../../../src/pages/carrito.js" /* webpackChunkName: "component---src-pages-carrito-js" */),
  "component---src-pages-como-comprar-js": () => import("./../../../src/pages/como-comprar.js" /* webpackChunkName: "component---src-pages-como-comprar-js" */),
  "component---src-pages-compra-realizada-copy-js": () => import("./../../../src/pages/compra-realizada copy.js" /* webpackChunkName: "component---src-pages-compra-realizada-copy-js" */),
  "component---src-pages-compra-realizada-js": () => import("./../../../src/pages/compra-realizada.js" /* webpackChunkName: "component---src-pages-compra-realizada-js" */),
  "component---src-pages-compras-js": () => import("./../../../src/pages/compras.js" /* webpackChunkName: "component---src-pages-compras-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cuenta-js": () => import("./../../../src/pages/cuenta.js" /* webpackChunkName: "component---src-pages-cuenta-js" */),
  "component---src-pages-direcciones-js": () => import("./../../../src/pages/direcciones.js" /* webpackChunkName: "component---src-pages-direcciones-js" */),
  "component---src-pages-finalizar-compra-js": () => import("./../../../src/pages/finalizar-compra.js" /* webpackChunkName: "component---src-pages-finalizar-compra-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lista-deseos-js": () => import("./../../../src/pages/lista-deseos.js" /* webpackChunkName: "component---src-pages-lista-deseos-js" */),
  "component---src-pages-marcas-js": () => import("./../../../src/pages/marcas.js" /* webpackChunkName: "component---src-pages-marcas-js" */),
  "component---src-pages-orden-resumen-js": () => import("./../../../src/pages/orden-resumen.js" /* webpackChunkName: "component---src-pages-orden-resumen-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("./../../../src/pages/preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-recovery-password-js": () => import("./../../../src/pages/recovery-password.js" /* webpackChunkName: "component---src-pages-recovery-password-js" */),
  "component---src-pages-restablecer-contrasena-js": () => import("./../../../src/pages/restablecer-contrasena.js" /* webpackChunkName: "component---src-pages-restablecer-contrasena-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-templates-brand-js": () => import("./../../../src/templates/Brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/Tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

